export default {
    nav: {
        home: 'Home',
        login: 'Login',
        reset_password: 'Reset Password',
        services: 'Services',
        events: 'Events',
        notices: 'Notices',
        reports: 'Reports',
        roles_permissions: 'Roles & Permissions',
        users: 'Users',
        profile: 'Profile',
        logout: 'Logout',
        signup: 'Sign Up',
    },
    profile: {
        profile: 'profile',
        user_account: 'User Account',
        personal_information: 'Personal Information',
        address_information: 'Address Information',
        change_password: 'Change Password',
        success_updated: 'Your Account has been updated',
        error_update: 'Failed to update profile'
    },
    services: {
        services: 'Services',
        add_service: 'Add Service',
        edit_service: 'Edit Service',
        delete_service: 'Delete Service',
        prompt_delete: 'Are you sure you want to delete this service?',
        search_services: 'Search services',
        name: 'Name',
        name_mt: 'Name (Maltese)',
        description: 'Description',
        description_mt: 'Description (Maltese)',
        actions: 'Actions',
        success_created: 'Service has been created',
        success_delete: 'Service has been deleted',
        success_updated: 'Service has been updated',
        error_create: 'Failed to create service',
        error_update: 'Failed to update service',
        error_delete: 'Failed to delete service',
        error_retrieve: 'Failed to retrieve services',
    },
    events: {
        events: 'Events',
        add_event: 'Add Event',
        edit_event: 'Edit Event',
        delete_event: 'Delete Event',
        search_events: 'Search events',
        name: 'Name',
        name_mt: 'Name (Maltese)',
        description: 'Description',
        description_mt: 'Description (Maltese)',
        location: 'Location',
        date: 'Date',
        upload_images: 'Upload Images',
        actions: 'Actions',
        delete_service: 'Delete Event',
        prompt_delete: 'Are you sure you want to delete this event?',
        success_created: 'Event has been created',
        success_updated: 'Event has been updated',
        success_delete: 'Event has been deleted',
        error_create: 'Failed to create event',
        error_update: 'Failed to update event',
        error_delete: 'Failed to delete event',
    },
    notices: {
        notices: 'Notices',
        add_notice: 'Add Notice',
        edit_notice: 'Edit Notice',
        delete_notice: 'Delete Notice',
        prompt_delete: 'Are you sure you want to delete this notice?',
        search_notices: 'Search notices',
        name: 'Name',
        name_mt: 'Name (Maltese)',
        description: 'Description',
        description_mt: 'Description (Maltese)',
        location: 'Location',
        date: 'Date',
        upload_images: 'Upload Images',
        actions: 'Actions',
        success_created: 'Notice has been created',
        success_updated: 'Notice has been updated',
        success_delete: 'Notice has been deleted',
        error_create: 'Failed to create notice',
        error_update: 'Failed to update notice',
        error_delete: 'Failed to delete notice',
        error_retrieve: 'Failed to retrieve notices',
    },
    users: {
        users: 'users',
        add_user: 'Add User',
        edit_user: 'Edit User',
        search_users: 'Search users',
        name: 'Name',
        company: 'Company',
        mobile_number: 'Mobile Number',
        phone_mobile_number: 'Phone / Mobile Number',
        address: 'Address',
        email: 'Email',
        phone: 'Phone',
        email_address: 'Email Address',
        vat_number: 'VAT Number',
        city: 'City',
        post_code: 'Post Code',
        password: 'Password',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this user?',
        success_created: 'User has been created',
        success_updated: 'User has been updated',
        success_delete: 'User has been deleted',
        error_create: 'Failed to create user',
        error_update: 'Failed to update user',
        error_delete: 'Failed to delete user',
        error_retrieve: 'Failed to retrieve users',
        user_details: 'User Details',
        update_user: 'Update User',
        delete_user: 'Delete User',
    },
    roles: {
        roles: 'roles',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        add_role: 'Add Role',
        edit_role: 'Edit Role',
        search_roles: 'Search roles',
        prompt_delete: 'Are you sure you want to delete this role?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve roles',
        error_create: 'Failed to create role',
        error_update: 'Failed to update role',
        error_delete: 'Failed to delete role',
        success_delete : 'Role has been deleted',
        success_created: 'Role has been created',
        success_updated: 'Role has been updated',
        error_retrieve_fields: 'Failed to retrieve fields',
        role_details: 'Role Details',
        delete_role: 'Delete Role',
    },
    reports: {
        generate_report: 'Generate Report',
        report_type: 'Report Type',
        room_name: 'Room Name',
        organisation_name: 'Organisation Name',
        from_date: 'From Date',
        to_date: 'To Date',
        choose_a_room: 'Choose a room',
        choose_an_organisation: 'Choose an organisation',
        choose_a_from_date: 'Choose a from date',
        choose_a_to_date: 'Choose a to date',
        report_options: 'Report Options',
        preview: 'Preview',
        organisations_report: 'Organisations Report',
        rooms_report: 'Rooms Report',
        total_number_of_bookings: 'Total number of bookings',
        total_number_of_hours_booked: 'Total number of hours booked',
        time_frame: 'Time Frame',
        to_date_must_be_after_from :'The to date must be after the from date',
    },
    permissions: {
        permission: 'permission',
        permissions: 'permissions',
        error_retrieve: 'Failed to retrieve permissions',
        error_save: 'Failed to save permission',
        details_saved: 'permission details have been saved',
        saved: 'permission has been saved',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        x_are_required: '{x} are required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
        passwords_must_match: 'Passwords must match',
        password_must_be_atleast_x_characters: 'Password must be at least {x} characters',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        forgot_password: 'Forgot password',
        forgot_password_text: 'Enter your email and we\'ll send you a link to reset your password.',
        password_has_been_updated: 'Your password has been updated!',
        setup_your_account: 'Setup your account.',
        account_created: 'Account successfully created',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        reset_password: 'Reset Password',
        enter_valid_email: 'Enter a valid email',
        email_sent: 'Email successfully sent',
        return_to_login: 'Return to Login',
        enter_your_new_password: 'Enter your new password',
        retype_password: 'Re-type password',
        failed_login: 'Login Failed',
        failed_reset: 'Reset Failed',
        identity_verified: 'Your identity has been verified!',
        set_new_password: 'Set your new password!',
        failed_signup: 'Signup Failed',
    },
    localities: {
        select_locality: 'Select locality',
        error_retrieve: 'Failed to retrieve localities',
    },
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    start: 'Start',
    finish: 'Finish',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    print: 'Print',
    send: 'Send',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    error_save_files: 'Error uploading files',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
    actions: 'Actions',
    back: 'Back',
    submit: 'Submit',
    assign: 'Assign',
    okay: 'Okay',
    signup: 'Sign Up',
}
