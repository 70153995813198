<template>
    <div class="services-update-modal">
        <ModalContainer :title="$t('services.add_service')" identifier="services-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.service.name.$model" identifier="name" :label="$t('services.name')"
                                   :placeholder="$t('services.name')" :disabled="is_saving"
                                   :has-error="$v.service.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.service.name.required">
                                {{ $t('validation.x_is_required', {x: $t('services.name')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.service.name_mt.$model" identifier="name" :label="$t('services.name_mt')"
                                   :placeholder="$t('services.name_mt')" :disabled="is_saving"
                                   :has-error="$v.service.name_mt.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.service.name_mt.required">
                                {{ $t('validation.x_is_required', {x: $t('services.name_mt')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.service.description.$model" identifier="description"
                                   :label="$t('services.description')"
                                   :placeholder="$t('services.description')" :disabled="is_saving"
                                   :has-error="$v.service.description.$error" autocomplete="off" :large-textarea="true"
                                   :use-textarea="true">
                        <template v-slot:errors>
                            <p v-if="!$v.service.description.required">
                                {{ $t('validation.x_is_required', {x: $t('services.description')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.service.description_mt.$model" identifier="description"
                                   :label="$t('services.description_mt')"
                                   :placeholder="$t('services.description_mt')" :disabled="is_saving"
                                   :has-error="$v.service.description_mt.$error" autocomplete="off"
                                   :large-textarea="true" :use-textarea="true">
                        <template v-slot:errors>
                            <p v-if="!$v.service.description_mt.required">
                                {{ $t('validation.x_is_required', {x: $t('services.description_mt')}) }}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "ServicesCreateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            service: {
                name: null,
                name_mt: null,
                description: null,
                description_mt: null,
            },
            is_saving: false,
        }
    },
    validations: {
        service: {
            name: {required},
            name_mt: {required},
            description: {required},
            description_mt: {required},
        },
    },
    methods: {
        close(status) {
            this.$modal.hide('services-create-modal', status);
        },
        save() {
            this.$v.service.$touch();
            if (this.$v.service.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.service.$model);

            this.$axios.post(`services`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('services.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('services.error_create')),
                    type: 'error',
                });
            });
        },
    },
}
</script>
