<template>
    <div class="privacy-policy-page">
        <h1>Privacy Policy</h1>

        <p>We are committed to protecting our visitors’ and/or user’s privacy and we will not collect
            any personal information about you as a visitor unless you provide it voluntarily.</p>

        <p>Pursuant to the General Data Protection Regulation (EU) 2016/679 (GDPR) and the Data Protection Act (Cap
            586),
            we have a legal duty to respect and protect any personal information we collect from you and we will abide
            by such duty.
            We take all safeguards necessary to prevent unauthorised access and we do not pass on your details collected
            from you
            as a visitor and/or user, to any third party unless you give us your consent to do so or as authorised by
            law.
            How do we process personal data?</p>

        <h2>1 E-Forms</h2>

        <p>We may collect personal information in the process of submission of forms through the website, addressed to a
            Government of Malta Ministry, Department or other Entity as the case may be. In such cases, the website is
            used as a User Interface and the information contained through forms is submitted for processing to the
            relevant Government of Malta Ministry, Department or other Entity. In this case, the retention period of the
            service in question would apply.</p>

        <h2>2) IP address and location</h2>

        <p>The web server keeps limited logs about IP addresses or the location of your computer on the Internet, for
            systems administration and troubleshooting purposes. We do not use IP address logs to track your session or your
            behaviour on our site.</p>

        <h2>3) e-ID</h2>

        <p>You may also authenticate through facilities provided by Central Authorities, such as using your eID account, in
            such instances, the authentication credentials will be limited to the current session through your web browser.</p>

        <h2>4) Contact Us/Feedback</h2>

        <p>When using this website’s online facilities, data subjects may be required to provide their contact details for
            contact purposes. All information and any personal data that you may decide to provide us in the Contact
            Us/Feedback form shall be processed only for the strict purpose of responding to your enquiry.</p>

        <p>Cookies are small pieces of data that the site transfers to the user’s computer hard drive when the user visits
            the website. This website makes limited use of cookies and consequently, for any further information, you are
            being guided to visit our Cookies Policy.</p>

        <h2>Your Rights</h2>

        <p>As an individual you may exercise your right to access the data held about you, therefore if you have any
            queries in relation to this data, if any, held by us please contact us by submitting your request to the
            appropriate data controller through the Contact Us page within the website.</p>

        <p>Although all reasonable efforts will be made to keep your information updated, you are kindly requested to
            inform us of any change referring to the personal data held by this Office. In any case if you consider that
            certain information about you is inaccurate, you may request rectification of such data. You also have the right
            to request the blocking or erasure of data. In case you are not satisfied with the outcome of your access
            request, you may refer a complaint to the Information and Data Protection Commissioner. Visit www.idpc.gov.mt
            for contact details.</p>

        <p>To give you a better service, our site can connect you with a number of links to other local and international
            organisations and agencies. When connecting to such other websites you will no longer be subject to this policy
            but to the privacy policy of the new site.</p>

        <p>This site uses Secure Sockets Layer (SSL) to ensure secure transmission of your personal data. You should be
            able to see the padlock symbol in the status bar of browser’s address field. The url address will also start
            with https:// depicting a secure webpage. SSL applies encryption between two points such as your PC and the
            connecting server. Any data transmitted during the session will be encrypted or scrambled and then decrypted or
            unscrambled at the receiving end. This will ascertain that data cannot be read during transmission.</p>

        <p>If there are any changes to this privacy policy, we will replace this page with an updated version. It is
            therefore in your own interest to check the “Privacy Policy” page any time you access our web site so as to be
            aware of any changes which may occur from time to time.</p>

        <p>Any comments or suggestions that you may have and which may contribute to a better quality of service will be
            welcome and greatly appreciated.</p>
    </div>
</template>

<script>
export default {
    name: "Policy"
}
</script>

<style lang="scss" scoped>
    .privacy-policy-page {
        @apply p-16;

        & > h1 {
            @apply font-bold text-3xl mb-8;
        }

        & > h2 {
            @apply font-bold text-xl mt-8 mb-6;
        }

        & > p {
            @apply mb-4;
        }
    }
</style>