<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button className="--primary --small" :onclick="toggleCreate">
                    {{ $t('roles.add_role') }}
                </Button>
            </template>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('roles.search_roles')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>

            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('roles.search_roles')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="roles"
                :isLoading.sync="is_loading_roles"
                :search-options="{
                enabled: false,
            }"
                :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('roles.roles')}),
            }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'after'" class="td-after">
                        <Button className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                        <Button className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else style="text-transform: capitalize;">
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import RolesCreateModal from "@/components/roles/RolesCreateModal.vue";
import RolesUpdateModal from "@/components/roles/RolesUpdateModal.vue";

export default {
    name: "roles-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('roles.name'),
                field: 'attributes.name',
                sortable: false,
            },
            {
                label: this.$t('roles.permissions'),
                field: row => row.relationships.permissions.data ? row.relationships.permissions.data.map(p => p.attributes.name).join(', ') : '',
                sortable: false,
            }
        ];

        if (true)
            columns.push({
                label: this.$t('roles.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false,
            });

        return {
            columns: columns,
            roles: [],
            is_loading_roles: false,
            totalRecords: null,
            serverParams: {},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        toggleCreate() {
            this.$modal.show(
                RolesCreateModal, {},
                {
                    name: 'roles-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveRoles();
                    }
                }
            );
        },
        toggleUpdate(role) {
            this.$modal.show(
                RolesUpdateModal, {
                    role_id: role.id,
                },
                {
                    name: 'roles-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveRoles();
                    }
                }
            );
        },
        toggleDelete(role) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('roles.delete_role'),
                    message: this.$t('roles.prompt_delete'),
                    subtext: this.$t('roles.prompt_delete_subtext'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`roles/${role.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        text: this.$t('roles.success_delete'),
                                        type: 'success',
                                    });

                                    this.retrieveRoles();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('roles.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveRoles();
        },
        onSortChange(params) {
            let sort_by = params[0].field.split('.')[1];
            this.updateParams({sort_by: sort_by, sort_order: params[0].type});
            this.retrieveRoles();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.retrieveRoles();
        },
        retrieveRoles() {
            this.is_loading_roles = true;

            const term = this.serverParams.term;

            this.$axios.get('roles', {
                params: {
                    page: this.serverParams.page ?? 1,
                    term,
                    sort_by: this.serverParams.sort_by,
                    sort_order: this.serverParams.sort_order
                }
            })
                .then(({data}) => {
                    this.roles = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_roles = false;
                })
                .catch(e => {
                    this.is_loading_roles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('roles.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    mounted() {
        this.retrieveRoles();
    },

    head() {
        return {
            title: {
                inner: this.$t('nav.roles_permissions')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
