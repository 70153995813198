<template>
    <div class="events-create-modal">
        <ModalContainer :title="$t('events.add_event')" identifier="events-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.event.name.$model" identifier="name" :label="$t('events.name')"
                                   :placeholder="$t('events.name')" :disabled="is_saving"
                                   :has-error="$v.event.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.event.name.required">
                                {{ $t('validation.x_is_required', {x: $t('events.name')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.event.name_mt.$model" identifier="name" :label="$t('events.name_mt')"
                                   :placeholder="$t('events.name_mt')" :disabled="is_saving"
                                   :has-error="$v.event.name_mt.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.event.name_mt.required">
                                {{ $t('validation.x_is_required', {x: $t('events.name_mt')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.event.location.$model" identifier="location"
                                   :label="$t('events.location')"
                                   :placeholder="$t('events.location')" :disabled="is_saving"
                                   :has-error="$v.event.location.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.event.location.required">
                                {{ $t('validation.x_is_required', {x: $t('events.location')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputDateTime v-model="$v.event.date.$model" identifier="date"
                                       :label="$t('events.date')" :minute-interval="5"
                                       :placeholder="$t('events.date')" :disabled="is_saving"
                                       :has-error="$v.event.date.$error" :min-date="todayDate">
                        <template v-slot:errors>
                            <p v-if="!$v.event.date.required">
                                {{ $t('validation.x_is_required', {x: $t('events.date')}) }}
                            </p>
                        </template>
                    </FormInputDateTime>

                    <FormInputText v-model="$v.event.description.$model" identifier="description"
                                   :label="$t('events.description')"
                                   :placeholder="$t('events.description')" :disabled="is_saving"
                                   :has-error="$v.event.description.$error" autocomplete="off" :large-textarea="true"
                                   :use-textarea="true">
                        <template v-slot:errors>
                            <p v-if="!$v.event.description.required">
                                {{ $t('validation.x_is_required', {x: $t('events.description')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.event.description_mt.$model" identifier="description"
                                   :label="$t('events.description_mt')"
                                   :placeholder="$t('events.description_mt')" :disabled="is_saving"
                                   :has-error="$v.event.description_mt.$error" autocomplete="off" :large-textarea="true"
                                   :use-textarea="true">
                        <template v-slot:errors>
                            <p v-if="!$v.event.description_mt.required">
                                {{ $t('validation.x_is_required', {x: $t('events.description_mt')}) }}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>

                <div class="upload">
                    <label for="event-create-file-upload">
                        <font-awesome-icon :icon="['fal','upload']"/>
                        <p>{{ $t('events.upload_images') }}</p>
                    </label>
                </div>
                <input type="file" name="file" id="event-create-file-upload" accept="image/png, image/jpeg" multiple
                       @change="handleFileChange" :disabled="is_saving"/>

                <div v-if="event_images.length" class="image-section">
                    <div class="images-container">
                        <div v-for="(image, index) in event_images" class="image-wrapper">
                            <div class="image-container">
                                <button type="button" class="btn-delete" @click="removeAttachment(index)">
                                    <font-awesome-icon :icon="['far','times']"/>
                                </button>
                                <img :src="image.preview"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Button type="submit" className="--primary --small submit-btn" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import FormInputDateTime from "@/components/form/FormInputDateTime";

export default {
    name: "EventsCreateModal",
    components: {
        FormInputDateTime,
        FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer
    },
    data() {
        return {
            event: {
                name: null,
                name_mt: null,
                location: null,
                date: null,
                description: null,
                description_mt: null,
            },
            event_images: [],
            is_saving: false,
        }
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD HH:mm');
        },
    },
    validations: {
        event: {
            name: {required},
            name_mt: {required},
            location: {required},
            date: {required},
            description: {required},
            description_mt: {required},
        },
    },
    methods: {
        close(status) {
            this.$modal.hide('events-create-modal', status);
        },
        uploadFile(event_id, attachment, name) {
            let formData = new FormData();
            formData.append('name', name);
            formData.append('document', attachment);

            return this.$axios.post(`events/${event_id}/documents`, formData);
        },
        handleFileChange(e) {
            const files = Array.from(e.target.files);

            files.forEach(async file => {
                this.event_images.push({
                    file: file,
                    preview: await this.getBase64FromFile(file),
                    name: file.name,
                });
            });
        },
        getBase64FromFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        removeAttachment(index) {
            if (index < 0 || index >= this.event_images.length)
                return;

            this.event_images.splice(index, 1);
        },
        save() {
            this.$v.event.$touch();
            if (this.$v.event.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.event.$model);

            this.$axios.post(`events`, payload).then(async ({data}) => {
                console.log(this.event_images)
                if (this.event_images.filter(a => !a.id).length) {
                    const filePromises = [];

                    this.event_images.filter(a => !a.id).forEach(attachment => {
                        filePromises.push(this.uploadFile(data.data.id, attachment.file, attachment.name));
                    });

                    await Promise.all(filePromises).then(() => {
                    }).catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('error_save_files'),
                            type: 'error',
                        });
                    });
                }

                this.$notify({
                    text: this.$t('events.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('events.error_create')),
                    type: 'error',
                });
            });
        },
    },
}
</script>

<style lang="scss">
.events-create-modal {
    .upload {
        @apply mb-4 w-1/2;

        label {
            @apply py-3 mt-1 w-full font-bold rounded border-2 border-grey bg-grey flex flex-row items-center ml-auto;

            svg {
                @apply ml-auto text-black text-xl;
            }

            p {
                @apply mr-auto text-black font-bold text-sm ml-3;
            }
        }
    }

    .image-section {
        .images-container {
            @apply flex flex-row flex-wrap mb-4 -mx-3;

            .image-wrapper {
                @apply p-3 w-1/4;

                .image-container {
                    @apply border-2 border-grey-light rounded p-2 relative;

                    img {
                        @apply rounded;
                    }

                    .btn-delete {
                        @apply border-2 border-grey-light rounded flex flex-row items-center justify-center h-5 w-5 absolute right-0 top-0 bg-white;

                        margin-top: -0.625rem;
                        margin-right: -0.625rem;

                        svg {
                            @apply text-black;

                            font-size: .7rem;
                        }

                        &:active, &:focus {
                            @apply outline-none;
                        }
                    }
                }
            }
        }

        .upload-row {
            @apply flex flex-row justify-end mt-5;

            label {
                @apply py-4 px-7 font-bold rounded border-2 border-primary flex flex-row items-center ml-auto;

                svg {
                    @apply text-black text-xl;
                }

                p {
                    @apply text-black font-bold text-sm ml-3;
                }
            }
        }
    }

    input[type="file"] {
        @apply hidden;
    }
}
</style>
